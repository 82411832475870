<template>
  <div>
    <b-card>
      <div>
        <b-row>
          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex justify-content-between align-items-center mb-50">
              <h5>文本</h5>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="清空"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="convertText = ''"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="複製"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="copyConvertText"
                >
                  <b-img
                    src="/admin/images/table/copy1.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>

            <div>
              <BFormTextarea
                v-model="convertText"
                rows="20"
                autofocus
                class="site-modal-area"
                no-resize
                placeholder="輸入要編碼的文字"
                @input="submitEncode"
              />
            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center flex-md-column justify-content-center my-2"
          >
            <!-- （下） -->
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="編碼"
              class="actions-link-btn-arrow d-md-none d-block"
              @click="submitEncode()"
            >
              <!-- d-flex align-items-center actions-link-btn ml-25 -->
              <b-img
                src="/admin/images/table/arrow.svg"
                class="actions-link-btn-image rotate-down"
                rounded
              />
            </div>

            <!-- （上） -->
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="解碼"
              class="actions-link-btn-arrow d-md-none d-block"
              @click="submitDecode()"
            >
              <!-- d-flex align-items-center actions-link-btn ml-25  -->
              <b-img
                src="/admin/images/table/arrow.svg"
                class="actions-link-btn-image rotate-up"
                rounded
              />
            </div>

            <!-- （右） -->
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="編碼"
              class="actions-link-btn-arrow d-md-block d-none"
              @click="submitEncode()"
            >
              <!-- d-flex align-items-center actions-link-btn ml-25  -->
              <b-img
                src="/admin/images/table/arrow.svg"
                class="actions-link-btn-image rotate-right"
                rounded
              />
            </div>

            <!-- （左） -->
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="解碼"
              class="actions-link-btn-arrow d-md-block d-none"
              @click="submitDecode()"
            >
              <!-- d-flex align-items-center actions-link-btn ml-25  -->
              <b-img
                src="/admin/images/table/arrow.svg"
                class="actions-link-btn-image rotate-left"
                rounded
              />
            </div>

          </b-col>

          <b-col
            cols="12"
            md="5"
            class="mb-2"
          >
            <div class="d-flex justify-content-between align-items-center mb-50">
              <h5>Base64</h5>

              <div class="text-nowrap d-flex justify-content-end">
                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="清空"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="convertBase64 = ''"
                >
                  <b-img
                    src="/admin/images/table/refresh.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>

                <div
                  v-b-tooltip.hover.focus.v-secondary
                  title="複製"
                  class="d-flex align-items-center actions-link-btn ml-25"
                  @click="copyConvertBase64"
                >
                  <b-img
                    src="/admin/images/table/copy1.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </div>
            </div>

            <div>
              <BFormTextarea
                v-model="convertBase64"
                rows="20"
                class="site-modal-area"
                no-resize
                placeholder="輸入要解碼的文字"
                @input="inputDecode"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, VBTooltip, VBToggle, BFormTextarea,
} from 'bootstrap-vue'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
// import { useUserModal, useTodoGroupView } from '../../useTodo'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BFormTextarea,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  mixins: [useSwalToast],
  data() {
    return {
      convertBase64: '',
      convertText: '',
    }
  },
  methods: {
    // (觸發)編碼
    submitEncode() {
      const convert = window.btoa(this.convertText)
      this.convertBase64 = convert
    },

    // (觸發)解碼
    submitDecode() {
      try {
        const convert = window.atob(this.convertBase64)
        this.convertText = convert
      } catch (error) {
        this.useAlertToast(false, '解碼失敗，請檢查輸入的 Base64 字串')
      }
    },

    inputDecode() {
      try {
        const convert = window.atob(this.convertBase64)
        this.convertText = convert
      } catch (error) {
        // this.useAlertToast(false, '解碼失敗，請檢查輸入的 Base64 字串')
      }
    },

    // (複製)文本
    copyConvertText() {
      if (!this.convertText) return
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.writeText(this.convertText)
        .then(() => {
          this.useAlertToast(true, '文本已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },

    // (複製)Base64
    copyConvertBase64() {
      if (!this.convertBase64) return
      if (!navigator.clipboard) {
        this.useAlertToast(false, '當前環境不支援該功能')
        return
      }
      navigator.clipboard.writeText(this.convertBase64)
        .then(() => {
          this.useAlertToast(true, 'Base64已複製')
        })
        .catch(() => {
          this.useAlertToast(false, '無法讀取剪貼板內容')
        })
    },
  },
  setup() {
    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    // getUserListData()

    return {
      useAlertToast,
      useHttpCodeAlert,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
.actions-link-btn-arrow {
  transition: transform ease-out 200ms;
  padding: 5px;
  border-radius: 5px;
  .actions-link-btn-image {
    width: 30px;
    height: 30px;
  }

  // translateX(50%)

  &:hover {
    cursor: pointer;
    background-color: #9de0f64f;
  }

  .rotate-right {
    transform: rotate(0deg);
  }

  .rotate-up {
    transform: rotate(-90deg);
  }

  .rotate-left {
    transform: rotate(180deg);
  }

  .rotate-down {
    transform: rotate(90deg);
  }
}

.device-area-select {
  width: 150px;
}

.component-list {
  .site-modal-area {
    // max-width: 100%;
    word-break: break-all;
    // overflow-x: hidden;
  }
}

.loading-area {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}
</style>
